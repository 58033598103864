import { Timestamp } from 'firebase/firestore';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useEffect, useState } from 'react';
import { isDev } from '../environment';
import { db } from '../firebase';
import Logger from '../logger';
import { ClientInfo } from '../models/client-info';
import CookieLinkLaunch from '../models/cookie-link-launch';
import CookieLinkLaunchDetail from '../models/cookie-link-launch-details';
import { defaultScoutId, Scout } from '../models/scout';
import { getClientInfo } from '../repositories/client-info-repository';
import { getClientPlatformInfo } from '../repositories/client-platform-info-repository';
import { publishCookieLinkLaunchDetail } from '../repositories/cookie-link-launch-detail-repository';
import {
  getLatestCookieLinkLaunch,
  publishCookieLinkLaunch
} from '../repositories/cookie-link-launch-repository';
import { getNextScout, getScouts } from '../repositories/scout-repository';
import './Home.css';

function Home() {
  const [clientInfo, setClientInfo] = useState<ClientInfo | undefined>(undefined);
  const [scouts, setScouts] = useState<Scout[]>([]);
  const [defaultScout, setDefaultScout] = useState<Scout>({} as Scout);
  const [loadingScouts, setLoadingScouts] = useState<boolean>(false);
  const [loadingScoutsError, setLoadingScoutsError] = useState<boolean>(false);
  const [loadingScoutClick, setLoadingScoutClick] = useState<boolean>(false);
  const [loadingNextClick, setLoadingNextClick] = useState<boolean>(false);
  const [showRedirectDialog, setShowRedirectDialog] = useState<boolean>(false);
  const [redirectedScout, setRedirectedScout] = useState<Scout>({} as Scout);

  useEffect(() => {
    getClientInfo()
      .then((data) => {
        setClientInfo(data as ClientInfo);
      })
      .catch((error) => {
        console.warn('Unable to retrieve client info', error);
        setClientInfo(undefined);
      });
  }, []);

  useEffect(() => {
    setLoadingScoutsError(false);
    setLoadingScouts(true);
    getScouts(db)
      .then((data) => {
        data ? setScouts(data) : setScouts([]);
        const matchingDefaultScout = scouts.find((scout) => scout && scout.id === defaultScoutId);
        setDefaultScout(matchingDefaultScout ? matchingDefaultScout : ({} as Scout));
        setLoadingScouts(false);
      })
      .catch(() => {
        setScouts([]);
        setLoadingScouts(false);
        setLoadingScoutsError(true);
      });
  }, []);

  const generateScoutLinks = (scouts: Scout[]) => {
    if (!scouts?.length) {
      return [];
    }

    return scouts.map((scout) => {
      const enabled = scout.enabled || isDev();
      return (
        <Button
          key={scout.id}
          label={scout.name}
          className="flex align-items-center justify-content-center m-2 p-button-raised"
          loading={loadingScoutClick}
          disabled={loadingScoutClick || loadingNextClick || !enabled}
          onClick={() => onScoutClick(scout)}
        />
      );
    });
  };

  const onNextClick = () => {
    setLoadingNextClick(true);
    getLatestCookieLinkLaunch(db)
      .then((latestLaunch) => {
        let nextScout = undefined;
        if (latestLaunch) {
          nextScout = getNextScout(scouts, latestLaunch.scoutId);
        }
        setLoadingNextClick(false);
        onScoutClick(nextScout ? nextScout : scouts[0], false);
      })
      .catch((error) => {
        Logger.error(
          `Unable retrieve latest launch from datastore, defaulting to ${JSON.stringify(
            defaultScout
          )}`,
          error
        );
        setLoadingNextClick(false);
        onScoutClick(defaultScout, false);
      });
  };

  const onScoutClick = (scout: Scout, direct = true) => {
    setLoadingScoutClick(true);
    if (!scout) {
      scout = defaultScout;
    }

    const cookieLinkLaunch = {
      scoutId: scout.id,
      clickedAt: Timestamp.now()
    } as CookieLinkLaunch;

    publishCookieLinkLaunch(db, cookieLinkLaunch)
      .then((docRef) => {
        setLoadingScoutClick(false);

        const cookieLinkLaunchDetail = {
          scoutId: scout.id,
          clickedAt: Timestamp.now(),
          direct: direct,
          clientPlatformInfo: getClientPlatformInfo(),
          cookieLinkLaunch: docRef
        } as CookieLinkLaunchDetail;
        if (clientInfo) {
          cookieLinkLaunchDetail.clientInfo = clientInfo;
        }
        publishCookieLinkLaunchDetail(db, cookieLinkLaunchDetail)
          .catch((error) =>
            console.error('Unable to publish cookie-link-launch-detail record', error)
          )
          .finally(() => {
            Logger.log(`Redirecting to url for ${scout.id}: ${scout.url}`);
            setRedirectedScout(scout);
            setShowRedirectDialog(true);
            setTimeout(() => {
              if (!isDev()) {
                location.href = scout.url;
              } else {
                setRedirectedScout({} as Scout);
                setShowRedirectDialog(false);
              }
            }, 2000);
          });
      })
      .catch((error) => {
        Logger.error(`Unable to publish launch for scout ${JSON.stringify(scout)}`, error);
        setLoadingScoutClick(false);
        Logger.log(`Redirecting to url for ${scout.id}: ${scout.url}`);
        setRedirectedScout(scout);
        setShowRedirectDialog(true);
        if (!isDev()) location.href = defaultScout.url;
      });
  };

  let scoutLinksContent;
  if (loadingScouts) {
    scoutLinksContent = (
      <div className="flex align-items-center justify-content-center m-2 p-button-raised">
        Loading...
      </div>
    );
  } else if (loadingScoutsError) {
    scoutLinksContent = (
      <div className="flex align-items-center justify-content-center m-2 p-button-raised">
        <span style={{ color: 'red', fontWeight: '800' }}>Unable to load links</span>
      </div>
    );
  } else {
    scoutLinksContent = generateScoutLinks(scouts);
  }

  let sophieNotSellingContent;
  if (!loadingScouts && !loadingScoutsError) {
    const sophie = scouts.find((scout) => scout.id === 'sophie');
    if (sophie && !sophie.enabled) {
      sophieNotSellingContent = (
        <div className="flex align-items-center justify-content-center m-2 p-button-raised">
          <span style={{ fontWeight: '400' }}>
            &quot;Sophie the Scout&quot; is no longer selling cookies, but her little sister Blair
            would love to fill your cookie orders!
          </span>
        </div>
      );
    }
  }

  return (
    <div style={{ position: 'relative' }} className="Home">
      <img className="gs-logo" src="./images/gs-logo.png" />
      <h1 className="header-text">Thank you for supporting the Girl Scouts!</h1>
      <p>
        Follow the links below to order cookies directly from Girl Scouts of America. If you&apos;re
        in the Raleigh area you can choose local delivery, if you&apos;re not, shipping is available
        throughout the United States!
      </p>
      <p className="color-white">Choose the scout you want to order from to get started.</p>
      <div
        style={{
          display: scouts.filter((scout) => scout && scout.enabled).length < 2 ? 'none' : 'block'
        }}
      >
        <div className="scout-links flex flex-wrap justify-content-center">
          <Button
            label="Choose for me"
            className="flex align-items-center justify-content-center m-2 p-button-raised"
            loading={loadingNextClick}
            disabled={loadingScoutClick || loadingNextClick}
            onClick={() => onNextClick()}
          />
        </div>
      </div>
      <div>{sophieNotSellingContent}</div>
      <div className="scout-links flex flex-wrap justify-content-center">{scoutLinksContent}</div>
      <p>Questions about ordering?</p>
      <div className="flex flex-wrap justify-content-center">
        <div className="contact-block">
          <div>Contact Angie</div>
          <div>
            <span className="label">text:</span>301-648-7697
          </div>
          <div>
            <span className="label">email:</span>
            <a href="mailto:cookies@sophiethescout.com">cookies@sophiethescout.com</a>
          </div>
        </div>
        <div className="contact-block">
          <div>Contact Jeff</div>
          <div>
            <span className="label">text:</span> 410-707-2316
          </div>
        </div>
      </div>
      <Dialog
        header={
          'Thanks for supporting ' +
          (redirectedScout?.name?.length ? redirectedScout.name : 'the Girl Scouts') +
          '!'
        }
        modal={true}
        resizable={false}
        draggable={false}
        closable={false}
        visible={showRedirectDialog}
        style={{ width: '50vw' }}
        onHide={() => console.log('onHide()')}
      >
        <p>
          Please wait while you are redirected to the official Girl Scouts of America website to
          complete your order.
        </p>
      </Dialog>
    </div>
  );
}

export default Home;
